import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-credit-note-rep',
  templateUrl: './credit-note-rep.component.html',
  styleUrls: ['./credit-note-rep.component.css']
})
export class CreditNoteRepComponent implements OnInit {
  customer: any;
  fileName= 'credit_note_report.xlsx';

  
  filter_types_orderdate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  payment={
    '1':'Credit',
    '2':'Cash',
    '3':'Bank/ Card'
  }
  pageLoadingImg: boolean;
  crNoteRpt: any;
  repDate: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_address:any;
  branch_display_code: any;
  exportLoader: boolean;
  ExpcrNoteRpt: any;
  total_net_amnt: any;
  total_amnt: any;
  total_vat_amnt: any;
  total_roundoff: any;
  exptotal_net_amnt: any;
  exptotal_amnt: any;
  exptotal_vat_amnt: any;
  exptotal_roundoff: any;
  total_nos: any;
  exptotal_nos: any;
  country_dec: string;
  custid: any;
  ptype: any;
  branch_all: any;
  filter_branch_id:any;
  userType: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private activatedRoute: ActivatedRoute,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
   
    this.filter_branch_id = 1;
    this.getAllBranch();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.userType = this.coreService.getUserData('user_type');

    this.activatedRoute.params.subscribe(params => {
      this.custid = params['custid'];
      this.ptype = params['ptype'];
      if (this.custid && this.ptype) {
             
        searchval.append("cust_id", this.custid);
        searchval.append("period_type", this.ptype);
        searchval.append("filter_branch_id", this.filter_branch_id);
      }
    });
    this.apiService.creditNoteRpt(searchval, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      this.crNoteRpt = res['data']['data'];
       
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_nos = res.data.tot_result;
      this.total_net_amnt = res.data.Total_Net_amnt;
      this.total_amnt = res.data.Total_amnt;
      this.total_vat_amnt = res.data.Total_vat_amnt;
      this.total_roundoff = res.data.Total_roundoff;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });


    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types_orderdate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }


  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append("filter_branch_id", this.filter_branch_id);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomeralias(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    searchval.append("filter_branch_id", this.filter_branch_id);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }



  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    
   
   
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    
    this.apiService.creditNoteRpt(formdata.value, pageno).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.crNoteRpt = res['data']['data'];
      // // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_nos = res.data.tot_result;
      this.total_net_amnt = res.data.Total_Net_amnt;
      this.total_amnt = res.data.Total_amnt;
      this.total_vat_amnt = res.data.Total_vat_amnt;
      this.total_roundoff = res.data.Total_roundoff;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }


  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
    
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
  
    this.apiService.creditNoteRpt(formdata.value, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.ExpcrNoteRpt = res['data']['data'];
      this.exptotal_nos = res.data.tot_result;
      this.exptotal_net_amnt = res.data.Total_Net_amnt;
      this.exptotal_amnt = res.data.Total_amnt;
      this.exptotal_vat_amnt = res.data.Total_vat_amnt;
      this.exptotal_roundoff = res.data.Total_roundoff;
      
      
      this.repDate = res.data.Date;
      
        setTimeout(() => {
        this.exportexcel();
    }, 3000);



    });

  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
     
    
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
  
    this.apiService.creditNoteRpt(formdata.value, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.ExpcrNoteRpt = res['data']['data'];
      this.exptotal_nos = res.data.tot_result;
      this.exptotal_net_amnt = res.data.Total_Net_amnt;
      this.exptotal_amnt = res.data.Total_amnt;
      this.exptotal_vat_amnt = res.data.Total_vat_amnt;
      this.exptotal_roundoff = res.data.Total_roundoff;
      
      
      this.repDate = res.data.Date;
      
      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Common.customer_name'),
        this.translate.instant('Purchase.VAT_No'),
        this.translate.instant('Common.branch_ref_no'),
        this.translate.instant('Common.date_added'),
        this.translate.instant('Common.Pay_Type'),
        this.translate.instant('Table.amnt'),
        this.translate.instant('Table.vat_amount'),
        this.translate.instant('Purchase.round_off'),
        this.translate.instant('Table.total_amount'),
        this.translate.instant('Common.notes')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.ExpcrNoteRpt.forEach((item,i)=>{
          data.push([
            i+1,
            (this.translate.currentLang == 'Arabic') ? item.ledger_alias_AR : item.cr_note_cust_name,
            item.ledger_tin,
            item.branch_cr_note_no,
            this.datePipe.transform(item.cr_note_date, 'dd/MM/yyyy'),
            this.payment[item.cr_note_pay_type],
            this.currencyPipe.transform(item.cr_note_amnt, '', '', this.country_dec),
            this.currencyPipe.transform(item.cr_note_vat_amnt, '', '', this.country_dec),
            this.currencyPipe.transform(item.cr_note_round_off, '', '', this.country_dec),
            this.currencyPipe.transform(item.cr_note_ttl_net_amnt - item.cr_note_round_off, '', '', this.country_dec),
            item.cr_note_notes
          ])
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Table.crdt_note_rpt')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Sales.Total_No_cr_nots')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.total_nos,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_net_amnt')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_net_amnt - this.total_roundoff, '', '', this.country_dec),  valueXPos, headerHeight + 25);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_amnt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_vat_amnt, '', '', this.country_dec),  valueXPos, headerHeight + 35);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_round_off')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_roundoff, '', '', this.country_dec),  valueXPos, headerHeight + 40);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, heads, data, null);
    
       
        doc.save(`Credit Note Report.pdf`);

        this.exportLoader = false;

    });
    
  }

  
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
}
