import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import * as XLSX from 'xlsx';
import {LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-expense-tax-payment-summary',
  templateUrl: './expense-tax-payment-summary.component.html',
  styleUrls: ['./expense-tax-payment-summary.component.css']
})

export class ExpenseTaxPaymentSummaryComponent implements OnInit {
  fileName = 'payment_summary_report.xlsx';

  up: any;
  cmpny = '';
  userType = '';
  pageLoadingImg = false;
  info = [];
  exp_info = [];
  curpage = 1;
  lastpage = 1;
  perpage = 30;
  totalItem = 0;
  account = [];
  nonAccount = [];
  supp_all = [];
  exportLoader: boolean;
  repDate: any;
  ExprepDate: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  sortType = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  pageFrom: any;
  pgend: boolean;
  pgstart: boolean;
  period_type: any;
  total_res: any;
  total_amount: any;
  total_round_off: any;
  total_tax: any;
  exp_total_amount: any;
  exp_total_round_off: any;
  exp_total_tax: any;
  group_all: any;
  is_suppl_name: boolean;
  is_vat: boolean;
  is_round_off: boolean;
  is_notes: boolean;
  selecTed: any;
  is_ref_no: any;
  is_branch_ref_no: any;
  branch_display_name: any;
  branch_name: any;
  logoImgUrl: '';
  branch_display_cod: any;
  branch_open_date: any;
  today_date: any;
  country_dec: string;
  custid: any;
  ptype: any;
  date1: string;
  date2: string;
  branch_all: any;
  filter_branch_id: number;
  group_by: boolean;
  creditNAme: any;
  debitNAme: any;

  constructor(private apiService: ApiService, private coreService: CoreService ,private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private excelService: ExcelService,private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getAllBranch();
    this.filter_branch_id = 1;
  
    this.getAllAccount();
    this.getAllNonAccountLedgers();
    // this.getInfo(this.curpage);
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    this.is_suppl_name=true;
    this.is_vat=true;
    this.is_round_off=true;
    this.is_notes=true;
    this.group_by=false;
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("period_type", 't');

    this.activatedRoute.params.subscribe(params => {
      this.custid = params['custid'];
      this.ptype = params['ptype'];
      if (this.custid && this.ptype) {
             
        searchval.append("debit_acc", this.custid);
        if(this.ptype=='c'){
          
          this.date1= this.apiService.formatDate(params['d1']);
          this.date2= this.apiService.formatDate(params['d2']);
          searchval.append("period_type", this.ptype);
          searchval.append("date1", this.date1);
          searchval.append("date2", this.date2);
        }else{
          searchval.append("period_type", this.ptype);
        }
        
      }
    });

    this.apiService.expTaxPaymentReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pageFrom = res.data.from;
      this.total_amount = res.data.total_amount;
      this.total_tax = res.data.total_tax;
      this.total_round_off = res.data.total_round_off;
      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_cod = this.coreService.getUserData('branch_code');
    this.branch_open_date = this.coreService.getUserData('branch_open_date');

    this.country_dec=this.coreService.setDecimalLength();

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }
  
  searchAccGroup(search: string) {
    let searchval = new FormData();
    searchval.append("name", search);
    this.apiService.getAccGroup(searchval).subscribe((res) => {
      this.group_all = res['data'];
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    this.apiService.expTaxPaymentReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.total_amount = res.data.total_amount;
      this.total_tax = res.data.total_tax;
      this.total_round_off = res.data.total_round_off;
      this.pageFrom = res.data.from;
      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    expformdata.value.export = 'export';

    this.apiService.expTaxPaymentReport(expformdata.value, 1).subscribe((res: any) => {
      this.exp_info = res.data.data;
      this.exp_total_amount = res.data.total_amount;
      this.exp_total_tax = res.data.total_tax;
      this.exp_total_round_off = res.data.total_round_off;
      this.ExprepDate =  res.data.Date;


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  searchSuppliers(search: any) {
    let searchval = new FormData();
    searchval.append("supp_name", search);
    this.apiService.gettaxSuppByName(searchval).subscribe((res) => {
      this.supp_all = res['data'];
    });
  }

// pdf download
exportToPdf(formdata: any) {

  formdata.value.export = 'export';

  this.exportLoader = true;
  
  const lblXPos = 18;
  const valueXPos = 55;

  const heads = [
    this.translate.instant('Table.sl_no'),
    this.translate.instant('transaction.Pay_No'),
    this.translate.instant('transaction.Pay_Date'),
    this.translate.instant('transaction.Branch_Pay_No'),
    this.translate.instant('HOME.credit') + '\n' + this.translate.instant('Production.account'),
    this.translate.instant('HOME.debit') + '\n' + this.translate.instant('Production.account'),
    this.translate.instant('Table.amnt'), 
    this.translate.instant('Common.Total_Amount'),
   
  ];

  if(this.is_suppl_name) {
    heads.splice(6,0, this.translate.instant('Purchase.supplier'))
  }

  if(this.is_vat) {
    heads.splice(8,0, this.translate.instant('Common.vat'))
  }

  if(this.is_round_off) {
    heads.splice(9,0, this.translate.instant('Purchase.round_off'))
  }

  if(this.is_notes) {
    heads.splice(11,0, this.translate.instant('Common.notes'))
  }

  const rows = [];
  const headerHeight = 25;


  this.apiService.expTaxPaymentReport(formdata.value, 1).subscribe((res: any) => {
    this.today_date=res.data.date2;
    if (res.data && res.data.data) {
      // this.Expdispnote = res.data.show_notes;
      res.data.data.forEach((data: any,i) => {
        
        if (this.translate.currentLang == "Arabic") {
          this.creditNAme = data.creditor_ar;
          this.debitNAme = data.debitor_ar;
      } else {
          this.creditNAme = data.creditor;
          this.debitNAme = data.debitor;
      }
        const arr =[ 
          i+1,
          data.pay_no, 
          this.datePipe.transform(data.pay_date, 'dd/MM/yyyy'),
          data.branch_pay_no,
          this.currencyPipe.transform(data.pay_ttl_amount, '', '', this.country_dec),
          this.currencyPipe.transform(data.pay_gttl_amount, '', '', this.country_dec)
        ];

        if(this.translate.currentLang != 'Arabic'){
          arr.splice(4,0, data.creditor)
        } else if(this.translate.currentLang == 'Arabic'){
          arr.splice(4,0, data.creditor_ar)
        }

        if(this.translate.currentLang != 'Arabic'){
          arr.splice(5,0, data.debitor)
        } else if(this.translate.currentLang == 'Arabic'){
          arr.splice(5,0, data.debitor_ar)
        }

        if(this.is_suppl_name){
          arr.splice(6,0, data.etp_supplier_name)
        }
        
        if(this.is_vat) {
          arr.splice(8,0, this.currencyPipe.transform(data.pay_vat_amount, '', '', this.country_dec))
        }

        if(this.is_round_off) {
          arr.splice(9,0, this.currencyPipe.transform(data.pay_roundoff, '', '', this.country_dec))
        }

        if(this.is_notes) {
          arr.splice(11,0, data.pay_note)
        }

        rows.push(arr);

      });
    }

    


    let doc = this.excelService.getPdfObj();

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('PAYMENT SUMMARY', 85, headerHeight - 2);


  //
    doc.setFontSize(10);
    doc.text('Branch', lblXPos, headerHeight + 5);
    doc.setFontSize(11);
    doc.text(':  ' + this.branch_display_name+' ('+this.branch_display_cod+')',  valueXPos, headerHeight + 5);

    if (this.repDate) {
    doc.setFontSize(10);
    doc.text(`Period`, lblXPos, headerHeight + 10);
    doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`,  valueXPos, headerHeight + 10);
  }else{
    doc.setFontSize(10);
    doc.text(`Period`, lblXPos, headerHeight + 10);
    doc.text(`:  ${this.branch_open_date} To ${this.today_date}`,  valueXPos, headerHeight + 10);

  }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 15);
    doc.text(':  ' + this.currencyPipe.transform(this.total_amount, '', '', this.country_dec),  valueXPos, headerHeight + 15);

    doc.text(`${this.translate.instant('Table.total_round_off')}`, lblXPos, headerHeight + 20);
    doc.text(':  ' + this.currencyPipe.transform(this.total_round_off, '', '', this.country_dec),  valueXPos, headerHeight + 20);

    doc.text(`${this.translate.instant('Common.vat')}`, lblXPos, headerHeight + 25);
    doc.text(':  ' + this.currencyPipe.transform(this.total_tax, '', '', this.country_dec),  valueXPos, headerHeight + 25);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 27, lblXPos, heads, rows, this.logoImgUrl, this.branch_display_name);
    doc.save(`payment_summary.pdf`);
    this.exportLoader = false;

  });
  // if(this.is_notes){
  //   heads.splice(5,0, this.translate.instant('Purchase.notes'))
  // }

}

getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res:any) => {
    this.branch_all = res.data;
    // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

}




