import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-van-customer-transaction',
  templateUrl: './van-customer-transaction.component.html',
  styleUrls: ['./van-customer-transaction.component.css']
})
export class VanCustomerTransactionComponent implements OnInit {
  fileName = 'van_customer_transaction.xlsx';
  VanList: any;
  pageLoadingImg: boolean;
  repData: any;

  inv_type={
    '22': 'Invoice Due Receipt',
    '4': 'Sales',
    '9': 'Sales Return',
  }
  salesPriviewLoader: boolean;
  resultobj: any;
  purchaseItems: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  showdata: boolean;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  vch_type = [
    { id: 4, name: 'Sales' },
    { id: 22, name: 'Invoice Due Reciept' },
    { id: 9, name: 'Sales Return' },
  ];

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  logoImgUrl: string;
  tempImgUrl: string;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  qr_inv: any;
  recPriviewLoader: boolean;
  printPreview: any;
  selctdVan: any;
  customer: any;
  balance: any;
  branch_display_name: any;
  repDate: any;
  van_data: any;
  branch_name: any;
  cust_name: any;
  opening_blnce: any;
  exportLoader: boolean;
  branch_display_code: any;
  totRes: any;
  branch_address: any;
  branch_phone: any;
  branch_mob: any;
  footImgUrl: string;
  cmpny: any;
  cust_id: any;
  country_dec: string;
  PrdNAme: any;
  customer_det: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.getTemplete();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.branch_phone= this.coreService.getUserData('branch_phone');
    this.branch_mob= this.coreService.getUserData('branch_mob');
    this.cmpny = this.coreService.getUserData('cmpny');
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.sales','Common.Invoice_Due_Reciept','HOME.sales_return']).subscribe((res: string) => {    
     
    
      this.filterTypes = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
          this.vch_type = [
            { id: 4, name:  res['Common.sales'] },
            { id: 22, name:  res['Common.Invoice_Due_Reciept'] },
            { id: 9, name:  res['HOME.sales_return'] },
          ];

    });

  


  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.VanList = res['data'];
    });

  }

  selectVan(van) {
    if (van) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('van_id', van.van_id);

      this.apiService.vanCustTransactionRep(searchval).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.Date;
        this.van_data = van;
        this.totRes = res.total;
      });

    } else {
      // this.repData = [];

    }
  }

  filterSearch(formdata: { value: any; }){
    this.pageLoadingImg = true;
    formdata.value.van_id=this.selctdVan.van_id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCustTransactionRep(formdata.value).subscribe((res) => {
      this.repData = res.data;
      this.pageLoadingImg = false;
      // this.repDate = res.data.Date;
      this.totRes = res.total;
    });
  }

  sale_search(search) {
    this.salesPriviewLoader = true;
   
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
     
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }
  

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: 'P',
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      };
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }

      if (res.data.prnt_header_type === 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;

    });
  }

  getSalesRetPreview(prdn_id) {
    this.saleprivewLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });

    });

  }

  viewReceipt(id) {
    this.recPriviewLoader = true;
    this.apiService.getReceipt({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      
      console.log(this.customer);
    });

  }

  selecCust(ledg_id){
    if(ledg_id){

      const searchval = new FormData();
      searchval.append('ledger_id', ledg_id);
      this.apiService.getCustDetails(searchval).subscribe((res) => {
        this.customer_det = res.data;

        this.balance = this.customer_det.balance;
      
      });
      // this.balance = this.customer.find(x => x.ledger_id === ledg_id).balance;
      this.cust_name = this.customer.find(x => x.ledger_id === ledg_id).name;
      this.opening_blnce = this.customer.find(x => x.ledger_id === ledg_id).opening_balance;
    }
    
  }

  exportexcel(): void {
    this.exportLoader = true;
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('Common.date'),
      this.translate.instant('ACCOUNTS.Vch_No'),
      this.translate.instant('Common.customer'),
      this.translate.instant('Purchase.refno'),
      this.translate.instant('transaction.Branch_Ref_No'),
      this.translate.instant('Common.Vocher_type'),
      this.translate.instant('HOME.debit'),
      this.translate.instant('HOME.credit')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.repData.forEach(item=>{
        if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.alias}else{this.PrdNAme =  item.customer}
        data.push([
          this.datePipe.transform(item.vch_date, 'dd/MM/yyyy'),
          item.vch_no,
          this.PrdNAme,
          item.ref_no,
          item.branch_ref_no,
          this.inv_type[item.vch_vchtype_id],
          this.currencyPipe.transform(item.dr_amnt, '', '', this.country_dec),
          this.currencyPipe.transform(item.cr_amnt, '', '', this.country_dec)
        ])
      })

      data.push([
        '',
        '',
        '',
        '',
        '',
        '',
        this.currencyPipe.transform(this.totRes.tot_dr_amnt, '', '', this.country_dec),
        this.currencyPipe.transform(this.totRes.tot_cr_amnt, '', '', this.country_dec)
      ])
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + 'Customer Transaction Van Based',  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Van.name')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.van_data.van_name + ' - ' + this.van_data.van_code,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('ACCOUNTS.Company_Branch')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.branch_name,  valueXPos, headerHeight + 25);

      if(this.cust_id){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.CUSTOMER')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.cust_name,  valueXPos, headerHeight + 30);
      }
      
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_dr_amount')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.totRes.tot_dr_amnt, '', '', this.country_dec),  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_cr_amount')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.totRes.tot_cr_amnt, '', '', this.country_dec),  valueXPos, headerHeight + 40);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Cash_Sales')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.totRes.tot_cash_sale, '', '', this.country_dec),  valueXPos, headerHeight + 45);

      if(this.cust_id){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Opening_Balance')}`, lblXPos, headerHeight + 50);

        if(this.opening_blnce < 0){
          doc.setFontSize(10);
          doc.text(':  ' + this.opening_blnce + 'CR',  valueXPos, headerHeight + 50);
        } else if(this.opening_blnce > 0){
          doc.setFontSize(10);
          doc.text(':  ' + this.opening_blnce + 'DR',  valueXPos, headerHeight + 50);
        }
      }

      if(this.repData){
        doc.setFontSize(14);
        doc.text(`${this.translate.instant('HOME.VOUCHERS')}`, lblXPos, headerHeight + 60);

        if(this.cust_id){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('ACCOUNTS.Closing_Balance')}`, lblXPos, headerHeight + 55);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.balance.bal_amt, '', '', this.country_dec) + ' ' + this.balance.bal_sign,  valueXPos, headerHeight + 55);
        }
      }

      doc = this.excelService.addTableToPdf(doc, headerHeight + 65, lblXPos, heads, data, null);
  
     
      doc.save(`Customer Transaction Van Based.pdf`);


    
  }

}
