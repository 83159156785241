import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent implements OnInit {
  credit_note_date: any;
  customer: any;

  pay_types = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank / Card' }
  ];
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];

  defLedger: any[];
  payment_type: any;
  Account: string;
  searchleadger: any;
  selectledgers = <any>[{
    // salesub_prd:<any>'',
    select_account: null,
    unit_price: 0,
    discount: 0,
    vat_type: 5,
    vat_amnt: 0,
    Amnt: 0,
  }];

  tax_types = [
    { id: 3, name: 'Zero Rated' },
    { id: 1, name: 'Standard Rated @ 5%' },
    { id: 2, name: 'Standard Rated @ 50%' },
    { id: 5, name: 'Standard Rated @ 15%' }
  ];

  tax_name = {
    1: '5',
    2: '50',
    5: '15',

  }
  pay_name = {
    1: 'Credit',
    2: 'Cash',
    5: 'Bank',

  }

  private modalRef: NgbModalRef;
  closeResult: string;
  isSubmitInProg: boolean;

  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id: 0,
    usr_type: 0,
    is_supplier: false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  userType: string;
  cust_resultobj: any = {};
  totalAmnt = <any>0;
  netAmnt = <any>0;
  totVatAmnt = <any>0;
  roundOff = <any>0;

  printError = []; errObjArr = {
    accntErr: null, custErr: null,

  };
  cust_id = <any>[{}];
  valErrors = <any>{};
  Ref: any;
  br_cr_no: any;
  notes: any;
  Reference: any;
  pageLoadingImg: boolean;
  vat_no: any;
  price_group_id: any;
  valid_till_date: Date;
  cust_balance: any;
  cust_bal_sign: any;
  sale_pay_type: number;
  grndttl: number;
  cr_note_file: any;
  imageError: any;
  savedTemplate: { logo: any; header_type: any; };
  logoImgUrl: string;
  tempImgUrl: string;
  branch_display_name: any;
  branch_name: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  crNoteData: any;
  previewLoader: boolean;
  is_prew_description: any;
  sales_settings: any;
  inv_type: any = 1;
  print_style: any;
  terms: any;
  up: any;
  search_type: any;
  purchtypesel: number;
  prd_tax_cat_id: number;
  disable_sales_without_stock: any;
  sales_print_name: any;
  sales_duplicate_Item: any;
  listing: boolean;
  searchLoadingImg: boolean;
  list_sales: any;
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  list_cr_note: any;
  importLoader: boolean;
  saleqt_date: string | number | Date;
  editMessage: boolean;
  country_dec: string;
  path = '';
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
  ];

  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = 0;
  cmpny = '';
  einv_simplfied_print_style = 1;
  invoiceId: any;
  invoice_type: any;
  customer_det: any;
  headerSalesEinvoiceLink: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal,private translate: TranslateService,private ActiveRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.xmlPath = this.apiService.PHP_API_SERVER + '/public/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');
    this.path = this.apiService.PHP_API_SERVER + '/' ;
    this.headerSalesEinvoiceLink = this.ActiveRoute.snapshot.paramMap.get('einvoice_pending');

  
    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
      }
      if (this.headerSalesEinvoiceLink && this.headerSalesEinvoiceLink==1){
        this.listing = true
        this.invoice_type=2
        this.lisCrNotes(1);
      }else{
        this.invoice_type=3;
        this.lisCrNotes(1);
      }

    });

    this.country_dec=this.coreService.setDecimalLength();
    this.credit_note_date = new Date();
    this.payment_type = 2;
    this.nextRefNo()
    this.editMessage = false;
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_name = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.up = this.coreService.getUserPrivilage();
    
    this.getTemplete();
    this.getSalesSetting();
  
  


  }




  addCreditNotes(formgroup) {


    this.printError = [];

    if (this.payment_type != 1) {


      if (this.Account == '' || this.Account == undefined) {
        this.errObjArr.accntErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });

      } else {
        this.errObjArr.accntErr = "f";

      }
    }
    if (this.payment_type == 1) {
      this.errObjArr.accntErr = "f";
    }


    if (this.cust_id == "" || this.cust_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (this.printError.length <= 0) {

      if (this.validateForm()) {

        const searchval = new FormData(formgroup);
        console.log(this.selectledgers);

        this.credit_note_date = (this.credit_note_date) ? this.apiService.formatDate(this.credit_note_date) : this.credit_note_date;
        searchval.append("credit_note_date", this.credit_note_date);
        searchval.append("payment_type", this.payment_type);
        searchval.append("Account", this.Account);
        searchval.append("selectledgers", JSON.stringify(this.selectledgers));
        searchval.append("cust_id", JSON.stringify(this.cust_id));
        searchval.append("total_amnt", this.totalAmnt);
        searchval.append("total_vat_amnt", this.totVatAmnt);
        searchval.append("net_amnt", this.netAmnt);
        searchval.append("round_off", this.roundOff ? this.roundOff : 0);
        searchval.append("inv_type", this.eInvoiceSupport ? this.inv_type : '');
        searchval.append("inv_sub_type", this.eInvoiceSupport ? this.inv_sub_type : '');

        console.log(searchval);

        // searchval.append("cust_name", this.cust_id.name);

        // const searchval = {
        //   'credit_note_date': this.credit_note_date,
        //   // 'valid_till_date': this.valid_till_date,
        //   'Ref': this.Ref,
        //   'br_cr_no': this.br_cr_no,
        //   'payment_type': this.payment_type,
        //   'Account': this.Account,
        //   'selectledgers': this.selectledgers,
        //   'cust_id': this.cust_id,
        //   'Reference': this.Reference?this.Reference:'',
        //   'notes': this.notes?this.notes:'',
        //   'total_amnt':this.totalAmnt,
        //   'total_vat_amnt': this.totVatAmnt ,
        //   'net_amnt': this.netAmnt,
        //   'round_off': this.roundOff?this.roundOff:0,
        //   'cr_note_file': this.cr_note_file ?this.cr_note_file:'',
        // 'cust_name': (this.cust_id) ? this.cust_id.name : 'Cash Customer'
        // }

        if (confirm('Do you wish to continue?')) {

          this.pageLoadingImg = true;

          this.apiService.addCreditNotes(searchval).subscribe((res) => {
            let isError = false;
            if(this.eInvoiceSupport){
              if(res.error && res.error['error']){
                this.coreService.showMessage(res.error['error'], 4000);
                isError = true;
                this.pageLoadingImg = false;
              }
              if(res.message && res.message.preview){
                this.coreService.showMessage(res.message.msg, 4000);
              }
            }
            if(!isError){
              if (res.message.preview) {
                this.crNoteData = res.message['preview'];
                this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
                this.previewLoader = false;
                // this.qr_inv = this.saleQtnData['qr_link'];
                $('#printviewBtn').click();
                console.log(this.crNoteData);

              }


              this.pageLoadingImg = false;
              // if (res.error != null) {
              //   this.resultobj = res.error;
              //   this.pageLoadingImg = false;
              //   for (var value in this.resultobj) {
              //     this.validationMsg += this.resultobj[value].toString() + '<br>';
              //   }
              // }
              // if (res.message) {
              //   if(print){
              //     this.saleQtnData = res.message['preview'];
              //     this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
              //     this.previewLoader = false;
              //     this.qr_inv = this.saleQtnData['qr_link'];
              //     $('#printviewBtn').click();
              //   } else{
              //     this.coreService.showMessage(res.message['msg']);
              //   }
              this.pageLoadingImg = false;
              this.cust_id = null
              this.payment_type = 1;
              this.Reference = '';
              this.notes = '';
              this.Account = '';
              this.cr_note_file = '';
              this.selectledgers = [{
                select_account: null,
                unit_price: 0,
                discount: 0,
                vat_type: 5,
                vat_amnt: 0,
                Amnt: 0,
              }];
              this.roundOff = 0;
              this.imageError = '';
              this.lisCrNotes(1);
              this.nextRefNo();
              this.sumSaleTotal();
              // setTimeout(() => {
              //   this.selPriduct.last.focus();
              // }, 500);
              // }
            }
            
            this.apiService.updateEinvoiceNotificationChange();
          });

          


        }

      }


    }


  }
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomeralias(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if (this.payment_type === 2)
      searchval.append('ledger_grp', '3');
    else if (this.payment_type === 3)
      searchval.append('ledger_grp', '4');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }

  changePayType() {
    this.defLedger = [];
    if (this.payment_type == 1) {
      this.Account = '';
    } else if (this.payment_type === 2) {
      if (this.Account != '0') {
        this.defLedger = this.coreService.getUserData('cash_ledger');
        this.Account = this.coreService.getUserData('usr_cash_ledger_id');
        if (!this.Account) {

          this.apiService.getBranchCashAccount().subscribe((led_res) => {
            this.defLedger = led_res.data.ledger;
            this.Account = led_res.data.taxled_ledger_id;
          });
        }
      }
    } else if (this.payment_type === 3) {
      if (this.Account != '0') {
        this.defLedger = this.coreService.getUserData('bank_ledger');
        this.Account = this.coreService.getUserData('usr_bank_ledger_id');
      }
    }
  }

  // form search  ledger
  searchLedger(search: string) {
    $('#report-wrapper').hide();

    const searchval = new FormData();
    searchval.append('search', search);
    this.apiService.searchIDELedgerList(searchval, 1).subscribe((res: any) => {
      this.searchleadger = res.data;
      // this.selecTed = [];
    });

  }

  addNewRow() {

    this.selectledgers.push({
      select_account: null,
      unit_price: 0,
      discount: 0,
      vat_type: 5,
      vat_amnt: 0,
      Amnt: 0,

    });
    // setTimeout(() => {
    //   this.selPriduct.last.focus();
    // }, 500);

  }

  remove(index) {
    this.selectledgers.splice(index, 1);
    this.sumSaleTotal();
  }


  sumSaleTotal() {

    this.totalAmnt = 0;
    this.totVatAmnt = 0;
    this.netAmnt = 0;
    this.grndttl = 0;

    for (var i = 0; i < this.selectledgers.length; i++) {
      // console.log('sub_disc ' + this.salesItems[i]['item_disc']);
      this.totalAmnt = Number(this.totalAmnt) + (Number(this.selectledgers[i].unit_price) - Number(this.selectledgers[i].discount));
      this.totVatAmnt = Number(this.totVatAmnt) + (Number(this.selectledgers[i].vat_amnt));
      // this.grndttl = Number(this.grndttl) + Number(this.selectledgers[i].);
      // this.totItemDiscWithoutBillDisc = Number(this.totItemDiscWithoutBillDisc) + Number(this.salesItems[i]['item_disc_WithoutBillDisc']);
      // this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['salesub_tax_amnt']);
      this.netAmnt = Number(this.netAmnt) + Number(this.selectledgers[i].Amnt);

    }


  }

  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = 'createCustOrSup';
    if (this.userType == 'ADMIN') {
      this.customer_dt.usr_type = 1;
    } else {
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.cust_resultobj = res.error;
      } else {
        this.cust_resultobj = {};
        this.modalRef.close();
        this.clearCustForm();
      }
    });
  }

  clearCustForm() {
    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email: '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id: 0,
      usr_type: 0,
      is_supplier: false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
  }

  selectTaxType(vattypeid, i) {
    if (vattypeid == 1) {

      this.selectledgers[i].vat_amnt = ((this.selectledgers[i].unit_price - this.selectledgers[i].discount) / 100) * 5;
      this.selectledgers[i].Amnt = this.selectledgers[i].vat_amnt + (this.selectledgers[i].unit_price - this.selectledgers[i].discount);

    }
    if (vattypeid == 3) {

      this.selectledgers[i].vat_amnt = 0;
      this.selectledgers[i].Amnt = this.selectledgers[i].vat_amnt + (this.selectledgers[i].unit_price - this.selectledgers[i].discount);

    }
    if (vattypeid == 2) {

      this.selectledgers[i].vat_amnt = ((this.selectledgers[i].unit_price - this.selectledgers[i].discount) / 100) * 50;
      this.selectledgers[i].Amnt = this.selectledgers[i].vat_amnt + (this.selectledgers[i].unit_price - this.selectledgers[i].discount);

    }
    if (vattypeid == 5) {

      this.selectledgers[i].vat_amnt = ((this.selectledgers[i].unit_price - this.selectledgers[i].discount) / 100) * 15;
      this.selectledgers[i].Amnt = this.selectledgers[i].vat_amnt + (this.selectledgers[i].unit_price - this.selectledgers[i].discount);

    }
    this.sumSaleTotal();
  }

  validateForm() {
    this.valErrors = {};

    let retVal = true;
    this.selectledgers.forEach((element, i) => {
      if (element.select_account == '' || element.select_account == null) {
        this.valErrors['select_acnt_' + i] = { msg: 'Required' }
        retVal = false;
      }

      element.discount = +element.discount;
      element.unit_price = +element.unit_price;

      if ((+element.unit_price == 0) || (+element.unit_price <= +element.discount)) {
        this.valErrors['unit_price_' + i] = { msg: 'Required' }
        retVal = false;
      }
    });




    return retVal;
  }

  selectCustomer(cust) {
    if (cust) {
      this.vat_no = cust.vat_no;
      if (cust.price_group_id) {
        this.price_group_id = cust.price_group_id;
      }
      let date = new Date(this.saleqt_date);
      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);

      const searchval = new FormData();
      searchval.append('ledger_id', cust.ledger_id);
      this.apiService.getCustDetails(searchval).subscribe((res) => {
        this.customer_det = res.data;
        if(res.data){
        
          this.cust_balance = this.customer_det.balance.bal_without_sign;
          this.cust_bal_sign = this.customer_det.balance.bal_sign;
          // this.current_dues = this.customer_det.crd_limit;
          // this.cust_bal = this.customer_det.balance.bal_amt;
        }else{
          this.cust_balance = 0;
          this.cust_bal_sign = '';
        }
      });
      // this.cust_balance = cust.balance.bal_without_sign;
      // this.cust_bal_sign = cust.balance.bal_sign;
    } else {
      this.cust_balance = 0;
      this.cust_bal_sign = '';
      // remove customer after selecting payment type credit, change payment type to cash
      if (this.sale_pay_type == 1) {
        this.sale_pay_type = 2;
      }
    }
  }


  nextRefNo() {
    let searchval = new FormData();
    this.apiService.getCreditNoteRefNo(searchval).subscribe((res) => {
      this.Ref = res['data'].ref_num;
      this.br_cr_no = res['data'].branch_cr_note_no;
    });
  }


  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.cr_note_file = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      // this.pdcImgUrl = event.target.result;
    }

  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      // const max_height = 250;
      // const max_width = 250;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }



      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          // const img_height = rs.currentTarget['height'];
          // const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          // if (img_height > max_height || img_width > max_width) {
          //   this.imageError =
          //     'Maximum dimentions allowed ' +
          //     max_height +
          //     ' X ' +
          //     max_width +
          //     'px';
          //   return false;
          // } else {
          // this.pdcImgUrl = e.target.result;
          // }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);




    }
  }


  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {

        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.inv_type = this.sales_settings.ss_inv_type;
        this.print_style = this.sales_settings.print_style;
        this.einv_simplfied_print_style = this.sales_settings.einv_simplfied_print_style;
        this.terms = this.sales_settings.sales_default_terms;
        this.search_type = this.sales_settings.search_type;
        // if(this.inv_type==3){
        //   this.purchtypesel = 2;
        //   this.prd_tax_cat_id = 3;
        //   this.selectTaxType(2);
        // }
        this.disable_sales_without_stock = this.sales_settings.disable_sales_without_stock;
        this.sales_print_name = this.sales_settings.sales_print_name;
        this.sales_duplicate_Item = this.sales_settings.sales_duplicate_item;
      } else {
        this.disable_sales_without_stock = 0;
        this.inv_type = 1;
        this.sales_print_name = 1;
        this.print_style = 1;
        this.terms = '';
        this.search_type = 1;
      }
      // this.updatePayment();
    });

  }

  showList(val) {

    if (val) {
      this.listing = false; // Invoice Page
      // setTimeout(() => {
      //   this.newInvoice(this.listing);
      // }, 500);

    } else {
      this.listing = true; // Listing Page
    }

  } getCrNoteList


  lisCrNotes(pageNo = 1, keyword = '') {
    let searchval = new FormData();
    if (keyword != '')
      searchval.append("keyword", keyword);
    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }
    this.searchLoadingImg = true;
    this.apiService.getCrNoteList(searchval, pageNo).subscribe((res) => {
      this.list_cr_note = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
      this.headerSalesEinvoiceLink=null;
    });
  }


  Select_invoiceType(id){

   
    if (id){
      this.invoiceId=id;
      let searchval = new FormData();
      searchval.append("invoiceType_id",this.invoiceId);
      this.searchLoadingImg = true;
    this.apiService.getCrNoteList(searchval,1).subscribe((res) => {
      this.list_cr_note = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
    });

    }
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  previewCrNotes(id) {
    let searchval = new FormData();
    searchval.append("cr_note_no", id);
    this.previewLoader = true;
    this.apiService.getCrNotePreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.crNoteData = res.data['preview'];
        this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
        this.previewLoader = false;

        // this.qr_inv = this.saleQtnData['qr_link'];
      }
    });
  }

  editCrNotes(data) {

    this.searchLoadingImg = true;
    this.searchDefaultLedger('');
    this.searchLedger('');
    let searchval = new FormData();
    searchval.append("cr_note_no", data);
    this.importLoader = true;
    this.apiService.getCrNoteDet(searchval).subscribe((res: any) => {
      this.searchLoadingImg = false;
      this.inv_type=res.data.is_simplified==1 ? 2 : 1;

      this.inv_sub_type=res.data.zatca_inv_sub_type.toString().padStart(5, '0');
      this.Ref = res.data.cr_note_no;
      this.credit_note_date = new Date(res.data.cr_note_date);
      // this.credit_note_date = res.data.cr_note_date;
      this.br_cr_no = res.data.branch_cr_note_no;
      this.payment_type = res.data.cr_note_pay_type;
      this.selectledgers = res.data.items;
      console.log(this.selectledgers);





      this.Account = res.data.cr_note_account;
      // this.cust_id = res.data.cr_note_cust_id;
      this.cust_id = res.data.customer
      this.Reference = res.data.cr_note_reference;
      this.notes = res.data.cr_note_notes;
      this.cr_note_file = res.data.cr_note_file;
      this.totalAmnt = res.data.cr_note_amnt;
      this.totVatAmnt = res.data.cr_note_vat_amnt;
      this.roundOff = res.data.cr_note_round_off;
      // this.sales_note = res.data.sales_notes;
      // this.terms = res.data.sales_terms;
      // this.sale_agent = res.data.sales_agent_ledger_id;
      this.sumSaleTotal();
      // this.changeAmnt();

      // this.importLoader = false;

      this.listing = false;
      // this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.editMessage = true;

    });

  }

  update(formgroup) {
    this.printError = [];

    if (this.payment_type != 1) {


      if (this.Account == '' || this.Account == undefined) {
        this.errObjArr.accntErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });

      } else {
        this.errObjArr.accntErr = "f";

      }
    }
    if (this.payment_type == 1) {
      this.errObjArr.accntErr = "f";
    }


    if (this.cust_id == "" || this.cust_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (this.printError.length <= 0) {

      if (this.validateForm()) {

        const searchval = new FormData(formgroup);
        console.log(this.selectledgers);

        this.credit_note_date = (this.credit_note_date) ? this.apiService.formatDate(this.credit_note_date) : this.credit_note_date;
        searchval.append("credit_note_date", this.credit_note_date);
        searchval.append("payment_type", this.payment_type);
        searchval.append("Account", this.Account);
        searchval.append("selectledgers", JSON.stringify(this.selectledgers));
        searchval.append("cust_id", JSON.stringify(this.cust_id));
        searchval.append("total_amnt", this.totalAmnt);
        searchval.append("total_vat_amnt", this.totVatAmnt);
        searchval.append("net_amnt", this.netAmnt);
        searchval.append("round_off", this.roundOff ? this.roundOff : 0);
        searchval.append("inv_type", this.eInvoiceSupport ? this.inv_type : '');
        searchval.append("inv_sub_type", this.eInvoiceSupport ? this.inv_sub_type : '');

        console.log(searchval);

        // searchval.append("cust_name", this.cust_id.name);

        // const searchval = {
        //   'credit_note_date': this.credit_note_date,
        //   // 'valid_till_date': this.valid_till_date,
        //   'Ref': this.Ref,
        //   'br_cr_no': this.br_cr_no,
        //   'payment_type': this.payment_type,
        //   'Account': this.Account,
        //   'selectledgers': this.selectledgers,
        //   'cust_id': this.cust_id,
        //   'Reference': this.Reference?this.Reference:'',
        //   'notes': this.notes?this.notes:'',
        //   'total_amnt':this.totalAmnt,
        //   'total_vat_amnt': this.totVatAmnt ,
        //   'net_amnt': this.netAmnt,
        //   'round_off': this.roundOff?this.roundOff:0,
        //   'cr_note_file': this.cr_note_file ?this.cr_note_file:'',
        // 'cust_name': (this.cust_id) ? this.cust_id.name : 'Cash Customer'
        // }

        if (confirm('Do you wish to continue?')) {

          this.pageLoadingImg = true;

          this.apiService.editCrNote(searchval).subscribe((res) => {


            let isError = false;
            if(this.eInvoiceSupport){
              if(res.error && res.error['error']){
                this.coreService.showMessage(res.error['error'], 4000);
                isError = true;
                this.pageLoadingImg = false;
              }
              if(res.message && res.message.preview){
                this.coreService.showMessage(res.message.msg, 4000);
              }
            }
            if(!isError){
            if (res.message.preview) {
              this.crNoteData = res.message['preview'];
              this.is_prew_description = this.crNoteData['items'].filter(x => x.item_descrp);
              this.previewLoader = false;
              // this.qr_inv = this.saleQtnData['qr_link'];
              $('#printviewBtn').click();
              console.log(this.crNoteData);

            }


            this.pageLoadingImg = false;
            this.editMessage = false;
            // if (res.error != null) {
            //   this.resultobj = res.error;
            //   this.pageLoadingImg = false;
            //   for (var value in this.resultobj) {
            //     this.validationMsg += this.resultobj[value].toString() + '<br>';
            //   }
            // }
            // if (res.message) {
            //   if(print){
            //     this.saleQtnData = res.message['preview'];
            //     this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
            //     this.previewLoader = false;
            //     this.qr_inv = this.saleQtnData['qr_link'];
            //     $('#printviewBtn').click();
            //   } else{
            //     this.coreService.showMessage(res.message['msg']);
            //   }
            this.pageLoadingImg = false;
            this.cust_id = null
            this.payment_type = 1;
            this.Reference = '';
            this.notes = '';
            this.Account = '';
            this.cr_note_file = '';
            this.selectledgers = [{
              select_account: null,
              unit_price: 0,
              discount: 0,
              vat_type: 5,
              vat_amnt: 0,
              Amnt: 0,
            }];
            this.roundOff = 0;
            this.imageError = '';
            this.lisCrNotes(1);
            this.nextRefNo();
            this.sumSaleTotal();
            
            // setTimeout(() => {
            //   this.selPriduct.last.focus();
            // }, 500);
            // }
          }
          this.apiService.updateEinvoiceNotificationChange();
          });
          


        }

      }


    }

    
  }

  goBack() {
    this.listing = true;
    this.editMessage = false;
    // this.convertMessage=false;
    // this.button_show=true;
    this.clearForm();
  }

  clearForm() {

    this.pageLoadingImg = false;
    this.cust_id = null
    this.payment_type = 1;
    this.Reference = '';
    this.notes = '';
    this.Account = '';
    this.cr_note_file = '';
    this.selectledgers = [{
      select_account: null,
      unit_price: 0,
      discount: 0,
      vat_type: 5,
      vat_amnt: 0,
      Amnt: 0,
    }];
    this.roundOff = 0;
    this.imageError = '';
    this.sumSaleTotal();
    this.nextRefNo();
  }

  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      if(this.invoice_type &&this.eInvoiceSupport){
        searchval.append("invoiceType_id",this.invoice_type);
      }
      searchval.append("keyword", keyword);
    this.searchLoadingImg = true;
    this.apiService.searchCrNList(searchval, 1).subscribe((res) => {
      this.list_cr_note = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.searchLoadingImg = false;
    });
  }

  deleteCrNotes(crNo,crref){
    if (confirm('Are you sure to void this record ?')) {
      this.isSubmitInProg=true;
      this.apiService.voidCrNotes({ crNote_no: crNo ,crRef:crref}).subscribe((res) => {
        
        this.isSubmitInProg = false;
        this.lisCrNotes(1);
        this.apiService.updateEinvoiceNotificationChange();
      });
    }
  }

  langChange(){
    this.translate.get(['HOME.credit', 'Common.cash','HOME.Cash_Bank','Vat.zero_rated','Vat.stnderd_rated',
    'Common.tax_invoice', 'Common.simplified_tax_invoice','Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing'
    ]).subscribe((res: string) => {    
     


      // this.purchase_types = [
      //   { id: 1, name: res['Common.standard_rated_domestic']},
      //   { id: 2, name: res['Common.zero_rated_domestic'] },
      //   { id: 3, name: res['Common.exempt_sales'] },
      // ];
      this.pay_types = [
        { id: 1, name: res['HOME.credit'] },
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Cash_Bank'] }
    
      ];

      this.tax_types = [
        { id: 3, name: res['Vat.zero_rated'] },
        { id: 1, name: res['Vat.stnderd_rated']+'@ 5%' },
        { id: 2, name: res['Vat.stnderd_rated']+'@ 50%' },
        { id: 5, name: res['Vat.stnderd_rated']+'@ 15%' }
      ];

      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
    
    });

  }

  updatePayment() {
    this.inv_sub_type='00000';
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
  }

  eInvoiceResubmit(refno,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'creditNote',
        ref_no : refno
      }
      this.list_cr_note[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.list_cr_note[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.lisCrNotes(1);
         
        }
        this.apiService.updateEinvoiceNotificationChange();
      });

      
    }


  }
}
