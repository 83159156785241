import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
import { MatDialogModule} from '@angular/material';

// routing
import { SettingsRoutingModule} from './settings-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {MatStepperModule} from '@angular/material/stepper';
import { AngularEditorModule } from '@kolkov/angular-editor';




export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { RateSettingsComponent } from './rate-settings/rate-settings.component';

import { CompanyComponent } from './company/company.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { UserPrivilagesComponent } from './user-privilages/user-privilages.component';
import { LedgerPrivilagesComponent } from './ledger-privilages/ledger-privilages.component';
import { PrintComponent } from './print/print.component';
import { GeneralComponent } from './print/print-templates/sales/general/general.component';
import { LetterPadComponent } from './print/print-templates/sales/letter-pad/letter-pad.component';
import { AdvanceSettingsComponent } from './advance-settings/advance-settings.component';
import { RemoveUnusedProductsComponent } from './advance-settings/remove-unused-products/remove-unused-products.component';
import { PurchaseSettingsComponent } from './purchase-settings/purchase-settings.component';
import { SalesSettingsComponent } from './sales-settings/sales-settings.component';
import { InterBranchTransferSettingsComponent } from './inter-branch-transfer-settings/inter-branch-transfer-settings.component';
import { Template1Component } from './template1/template1.component';
import { SalesQtnSettingsComponent } from './sales-qtn-settings/sales-qtn-settings.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { DasboardSettingsComponent } from './dasboard-settings/dasboard-settings.component';
import { EnableDisableModuleComponent } from './enable-disable-module/enable-disable-module.component';
import { PriceCheckerSettingsComponent } from './price-checker-settings/price-checker-settings.component';
import { NotificationComponent } from './advance-settings/notification/notification.component';
import { GodownSettingsComponent } from './godown-settings/godown-settings.component';
import { ProductSettingsComponent } from './product-settings/product-settings.component';
import { DeliveryChallanSettingsComponent } from './delivery-challan-settings/delivery-challan-settings.component';
import { VanSettingsComponent } from './van-settings/van-settings.component';
import { CurrencyMasterComponent } from './currency-master/currency-master.component';
import {NgxPrintModule} from 'ngx-print';
import { MailSettingsComponent } from './mail-settings/mail-settings.component';
import { RemoveInactiveLedgerComponent } from './advance-settings/remove-inactive-ledger/remove-inactive-ledger.component';
import { SalesOrderSettingsComponent } from './sales-order-settings/sales-order-settings.component';
import { VoucherSettingsComponent } from './voucher-settings/voucher-settings.component';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { ExportAllproductsComponent } from './advance-settings/export-allproducts/export-allproducts.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { ExcelConvertComponent } from './advance-settings/excel-convert/excel-convert.component';
import { DatabaseClearanceComponent } from './advance-settings/database-clearance/database-clearance.component';
@NgModule({
  declarations: [CompanyComponent, BarcodeComponent,RateSettingsComponent, UserPrivilagesComponent, LedgerPrivilagesComponent, PrintComponent, GeneralComponent, LetterPadComponent, AdvanceSettingsComponent, RemoveUnusedProductsComponent, PurchaseSettingsComponent, SalesSettingsComponent, InterBranchTransferSettingsComponent, Template1Component, SalesQtnSettingsComponent, ClientSettingsComponent, DasboardSettingsComponent, EnableDisableModuleComponent, PriceCheckerSettingsComponent, NotificationComponent, GodownSettingsComponent, ProductSettingsComponent, DeliveryChallanSettingsComponent, VanSettingsComponent, CurrencyMasterComponent, MailSettingsComponent, RemoveInactiveLedgerComponent, SalesOrderSettingsComponent, VoucherSettingsComponent, LanguageSettingsComponent, ExportAllproductsComponent, GeneralSettingsComponent, ExcelConvertComponent, DatabaseClearanceComponent],
  imports: [
    CommonModule, 
    SettingsRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule, 
    ComfunctModule,  
    MatDialogModule, 
    DateInputsModule,
    MatStepperModule,
    AngularEditorModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
})
export class SettingsModule { }
